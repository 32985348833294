import React from 'react';

import Home from '../../pages/index';

import NotFound from '../../pages/404';
import { withPrefix } from 'gatsby-link';


import {Location, Router} from '@gatsbyjs/reach-router';


const Layout = () => {

  return (
    <Location>
    {({ location }) => (
              
          
            (typeof window !== 'undefined') &&
              <Router basepath={withPrefix("/")} location={location} key={location.pathname}>
                <Home exact path="/" />                
                <NotFound exact path="/404" />
                <NotFound default />
              </Router>
          
    )}   
   </Location>    
  )   
};



export default Layout;
