import React from 'react';


import Layout from './src/components/layout/layout';
import App from './src/components/App/app';
import { BreakpointProvider } from './src/providers/breakpoint';


export const wrapRootElement = ({ element }) => {
  return (
    <BreakpointProvider>
      {element}
    </BreakpointProvider> 
  )
};

export const wrapPageElement = ({ element, props }) => {
  
  
  
  return (    
    <App> 
     <Layout/>  
    </App>                  
  )
};
