import styled, { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import {motion} from 'framer-motion';



export const GlobalStyle = createGlobalStyle `
  ${reset}
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {    
    font-family: ${props => props.theme.fonts.family};    
    font: ${props => props.theme.fonts.font};
    color: ${props => props.theme.colors.color};
    background-color: ${props  => props.theme.colors.bg};    
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: flex;
  }

  *, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  #root {
  display: flex;
  
  }



  html {
    display: flex;        
    box-sizing: border-box;
    scroll-behavior: smooth;
    
    
  }

  body { 
    display: flex;
    justify-content:center;
    margin:0;
    padding:0;
    max-width:100%;
    
    
    line-height: 1;
    font-size: ${props => props.theme.fonts.sizeB}rem;    
    letter-spacing:${props => props.theme.spacings.xxSmall}em;    
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
    
  }

  ol, ul {
    list-style: none;
    text-decoration:none;
  }
  #sidelinks ul {
    list-style:none;
    padding:0;
    margin:0;
}

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    text-decoration: none;
  }

  #sidelinks a {
    color:black; 
    text-decoration:none;
}

  pre {
    display: block;
    padding: 2rem;
    margin-top: 4rem;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    border-radius: 5px;
    
    border: 1px solid #ddd;
    
  }

  video {
    max-width: 100%;
  }

  p {
    margin-bottom: 2rem;
  }
 

`;



export const HeaderContainer = styled.header`
    
`;

export const Logo = styled.div`  
       
`;


export const Title = styled.div`  
   
`;
export const Description = styled.div`  
   
`;


export const AppContainer = styled.div`
        
`;

export const MainContainer = styled.main`
    
`;

export const CloseContainer = styled(motion.div)`

`;

export const HomeContainer = styled.div`

`;
export const SideContainer = styled(motion.div)`

`;
export const ContentBottom = styled.div`

`;
export const ImageContainer = styled(motion.div)`

`;
export const ModalContainer = styled(motion.div)`

`;
export const ClientContainer = styled(motion.div)`

`;

export const FooterContainer = styled.footer` 
    
`;
export const ArrowContainer = styled(motion.div)`

`;
export const ClientArrowContainer = styled(motion.div)`

`;

export const TitleSection = styled(motion.div)`

`;
export const TitleContainer = styled(motion.div)`

`;

export const TextModal = styled(motion.p)`

`;

export const TextNormal = styled(motion.div)`

`;

export const BodyHead = styled.div`
    
`;

export const TextSection = styled(motion.div)`
 
`;


export const ModalFeature = styled(motion.div)`

`;
export const ModalPicture = styled(motion.div)`

`;

export const MainPicture = styled(motion.div)`


`;


export const NormalFeature = styled(motion.div)`

`;

export const NormalPicture = styled(motion.div)`

`;
