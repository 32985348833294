
export const theme = {
    fonts:{
        family:"-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,sans-serif,serif",
        font:"inherit",
        other:"Times New Roman",
        sizeXS:0.5,
        sizeS:0.75,
        sizeB:1 ,
        sizeL:1.25,
        sizeXL:1.5,
        sizeXXL:2,
        weightT:200,
        weightN:400,
        weightB:700
    },
    colors:{
        color: "inherit",
        other: "rgb(48, 48, 48)",
        bg:"inherit",
    },
    breakpoints:{
        mobile: 320,
        tablet: 768,
        desktop:1024,
    },
    spacings:{
        xxSmall: 0.1,
        xSmall: 0.2,
        small: 0.3,
        large: 0.4,
        xxLarge: 0.5
    },
    animations:{
        button: "box-shadow 0.3s ease",
        link: "color 0.2 s ease"
    },
    shadows:{
        shadow1: "0 px 5 px 20 px rgba(30,30,31,0.05)"
    },
    transitions:{
        transitionColor: "color 300 ms"
    }
};

export default theme;