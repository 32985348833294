import React,{useContext} from 'react';
import {useStaticQuery,graphql} from 'gatsby';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { BreakpointContext } from '../../providers/breakpoint';
import { CloseContainer, ClientContainer, ModalContainer, NormalPicture } from "../../global.css";
import {GatsbyImage,getImage} from 'gatsby-plugin-image';




const NormalMain = (props) =>{

  const styleAll = useContext(BreakpointContext);
  const normalPictureStyle = styleAll['NormalPictureStyle'];
  const modalContainerStyle = styleAll['ModalContainerStyle'];
  const closeContainerStyle  = styleAll['CloseContainerStyle'];
  const closeIconStyle = styleAll['CloseIconStyle']; 
  const clientContainerStyle  = styleAll['ClientContainerStyle'];
  const imageStyle = styleAll['ModalImageStyle'];
  
  const dataImageMainNormal = useStaticQuery(graphql`
  query dataImageMainNormalQuery {
  file(relativePath: {eq: "img_main.png"}) {
      childImageSharp {
      gatsbyImageData(pngOptions: {quality: 80}, placeholder: BLURRED)
      }
  }
  }
  `);

  const image = getImage(dataImageMainNormal.file.childImageSharp.gatsbyImageData);

  

  const OnDoubleClk = (event) => {
    props.OnDoubleClick();
    event.preventDefault();
    }

    return (
        <>    
        <NormalPicture
            key="normalpicturemain" 
            initial={{ opacity: 0, x: 0, y:-100 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 100, y: 0 }}
            transition={{
            type: "spring",
            mass: 0.5,
            stiffness: 50,
            duration:0.5,            
            }}
            style={normalPictureStyle}
          >

          <GatsbyImage image={image} onDoubleClick={OnDoubleClk} style={{height:"100%"}} alt="Multilocation-Video-Monitoring-App"/>

        </NormalPicture>

        {
          props.largeImage ?
           
          <ModalContainer
            key="modalimagemain"
            initial={{ opacity: 0, x: -200 , y: -200}}
            animate={{ opacity: 1, x: 0 , y: 0}}
            exit={{ opacity: 0, x: 200, y: 200 }}
            transition={{
            type: "spring",
            mass: 0.25,
            stiffness: 50,
            duration: 0.5,
            }}
            style={modalContainerStyle}
            onDoubleClick={OnDoubleClk}           
          >
            <CloseContainer style={closeContainerStyle}>
              <FontAwesomeIcon icon={faTimes} style={closeIconStyle} onClick = {OnDoubleClk} />
            </CloseContainer>
            <ClientContainer key="clientcontainer" style={clientContainerStyle}>            
            <GatsbyImage image={image} style={imageStyle} alt="Multilocation-Video-Monitoring-App" />
            </ClientContainer>
          </ModalContainer>
          

          :null        
        }
        </>
    )
};

export default NormalMain;

