import React from 'react';


import { Link } from 'gatsby';

const NotFound = () => {  

  

  return (
  
    <>
        <h1>This page is invalid <br/>
        <Link  to="/"> Click here ! Home Page</Link><br/></h1>  
    </>  
    
  )
};

export default NotFound;