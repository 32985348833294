import React,{useContext} from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {GatsbyImage,getImage} from 'gatsby-plugin-image';
import { ModalFeature, ModalPicture } from '../../global.css';
import { BreakpointContext } from "../../providers/breakpoint";
import { FeatureOptionsModal } from '../content/features';

const ModalOptions = () => {

  const styleAll = useContext(BreakpointContext);
  const modalPictureStyle = styleAll['ModalPictureStyle'];
  const modalFeatureStyle  = styleAll['ModalFeatureStyle'];

  const imageStyle = styleAll['ModalImageStyle'];
    const dataModalOptions = useStaticQuery(graphql`
    query dataModalOptionsQuery {
        file(relativePath: {eq: "img_download.png"}) {
            childImageSharp {
            gatsbyImageData(pngOptions: {quality: 80},placeholder: BLURRED)
          }
        }
      }
      
        
      `);
      
      const image = getImage(dataModalOptions.file.childImageSharp.gatsbyImageData);
      
      const ftr = {
        hidden: { opacity: 0},
        show: {
          opacity: 1 ,
        transition:{ 
          type: "spring",
          mass: 0.25,
          stiffness: 75,
          duration:1,
          staggerChildren:0.5,                   
          delayChildren:0.5,
            
          }
        },
       
      };
    
        return (
          <>
          <ModalPicture
          key="modalpictureoptions"
          style={modalPictureStyle}
          >
            <GatsbyImage image={image} style={imageStyle} alt="CCTV-Remote-Download-Options-Image" />
          </ModalPicture>
          <ModalFeature
          key="modalfeatureoptions"
          variants={ftr}
          initial="hidden"
          animate="show"
          style={modalFeatureStyle}
          >
            <FeatureOptionsModal/>
          </ModalFeature>
        </>    
            
        )
};

export default ModalOptions;