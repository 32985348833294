import React,{useContext} from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ModalFeature, ModalPicture } from "../../global.css";
import { BreakpointContext } from "../../providers/breakpoint";
import { FeatureAlertModal } from "../content/features";

const ModalAlert = () => {

  
  const styleAll = useContext(BreakpointContext);
  const modalPictureStyle = styleAll['ModalPictureStyle'];
  const modalFeatureStyle  = styleAll['ModalFeatureStyle'];
  const imageStyle = styleAll['ModalImageStyle'];
  const dataModalAlert = useStaticQuery(graphql`

  query dataModalAlertQuery {
    file(relativePath: {eq: "img_alert.png"}) {
        childImageSharp {
        gatsbyImageData(pngOptions: {quality: 80},placeholder: BLURRED)
      }
    }
  }
  `);

  const image = getImage(dataModalAlert.file.childImageSharp.gatsbyImageData);

  const ftr = {
    hidden: { opacity: 0},
    show: {
      opacity: 1 ,
    transition:{ 
      type: "spring",
      mass: 0.25,
      stiffness: 75,
      duration:1,
      staggerChildren:0.5,                   
      delayChildren:0.5,
        
      }
    },
   
  };


  return (
    <>
      <ModalPicture
      key="modalpicturealert"
      style={modalPictureStyle}
      >
        <GatsbyImage image={image} style={imageStyle} alt="CCTV-Alert-Video-Popup-Image" />
      </ModalPicture>
      <ModalFeature
      key="modalfeaturealert"
      variants={ftr}
      initial="hidden"
      animate="show"
      style={modalFeatureStyle}
      >
        <FeatureAlertModal/>
      </ModalFeature>
    </>
  );
};

export default ModalAlert;
