


export const StyleAll = (ftr) => {
    
   
    
    const factor = ftr;
  
        
    const ButtonTitleFontStyleFactored = {
      display:"flex",
      flexDirection:"row",      
      fontWeight: "500",
      lineHeight:"1",
      alignItems:"center",
      justifyContent:"center",
      padding:"0",
      overflow:"hidden",
      fontSize: "max(2vh,calc(1vh * "+factor+"))",      
    };

          
      const BodyTitleFontStyleFactored = {
        display:"flex",
        flexDirection:"row",       
        fontWeight: "600",
        lineHeight:"1",      
        paddingLeft:"0.6em",
        paddingTop:"0.3em",   
        paddingRight:"0.6em",   
        paddingBottom:"0.3em",        
        border:"0.1em solid lightGray",        
        borderRadius:"0.5em",        
        alignItems:"center",
        justifyContent:"center",
        textAlign:"center",
        overflow:"hidden", 
        fontSize:"max(2vh,calc(1vh * "+factor+"))" ,
      };
  
   
    const ButtonSectionStyleFactored = {
        display: "flex",
        flex:"1 1 auto",
        flexDirection:"row",
        flexWrap:"wrap",
        marginTop: "0.5vh",
        marginBottom: "0.5vh",
        padding:"0",
        alignItems:"center",
        justifyContent:"center",
        textAlign:"center",        
        border: "0.1em solid lightGray",        
        borderRadius:"1em",              
        overflow:"hidden",
        cursor:"pointer",             
        width: "100%",
        minHeight: "4vh",
        paddingRight:"0.25vw",
        paddingLeft:"0.25vw", 
    };

   

    const ButtonSectionStyle2Factored ={
        ...ButtonSectionStyleFactored,
        autoFocus:"true",
    };
  
   
    const ButtonContainerStyleFactored = { 
        display: "flex",        
        flexDirection:"column",
        flexWrap:"nowrap",
        order:(factor < 3) ? "1" :"0",
        margin:"0 auto",
        alignItems:"center",
        justifyContent:"center",       
        paddingRight:"0.25vw",
        paddingLeft:"0.25vw",  
        height:(factor <= 1) ? "40vh" 
                : (factor < 3) ? "40vh"
                : "75vh", 
        maxWidth: (factor <= 1) ? "90vw" 
                : (factor < 3) ? "38vw"
                : "19vw",
        width: (factor <= 1) ? "90vw"
                : (factor < 3) ? "34vw"
                : "17vw",
    };   
      
   
      const CloseContainerStyleFactored = {
        position:"relative",
        display:"flex",
        flexWrap:"nowrap",
        flexDirection:"row",        
        margin:"0 auto",
        paddingRight:"3vw",
        alignItems:"flex-start",
        justifyContent:"flex-end",
        width:"100%",
        minHeight:"5vh",
        height:"5vh",
        maxHeight:"5vh",
        objectFit:"contain",
        backgroundSize:"contain",       
        background:"inherit",
        backgroundColor:"inherit",
        color:"inherit",  
      };

    const CloseIconStyleFactored = {
        fontSize:"3vh",
        fontWeight:"900",
        lineHeight:"1.5",
        borderRadius:"50%",
        background:"inherit",
        backgroundColor:"inherit",
        color:"inherit",
    };

   

    const TextSectionStyleFactored = {
        display:"flex",
        flex:"1 0 auto",
        flexDirection:"row",
        flexWrap:"wrap",
        marginTop:"1vh",
        marginBottom:"1vh",        
        alignItems:"center",
        justifyContent:"space-around",        
        height:"auto",                
        overflow:"hidden",
        background:"inherit",
        backgroundColor:"inherit",
        color:"inherit",  
        paddingRight:"0.25vw",
        paddingLeft:"0.25vw",
        width:"100%",
    };

    const TextNormalStyleFactored = {
        display:"flex",        
        flexDirection:"row",
        flexWrap:"wrap",        
        fontWeight:"500",
        lineHeight:"120%",        
        padding:"0px",        
        alignItems:"center",
        justifyContent:"center",
        textAlign:"center",               
        overflow:"hidden",
        background:"inherit",
        backgroundColor:"inherit",
        color:"inherit",  
        fontSize:"max(3vh,calc(1vh * " +factor+"))", 
        width:"100%",
    };


    const BodyHeadStyleFactored = {
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"center",
        textAlign:"center",    
        margin:"0 auto",
        padding:"0em",  
        minHeight:"4vh",
        height:"4vh",        
        maxHeight:"4vh",   
        width:"100%",
        maxWidth:"100%",        
        overflow:"hidden",
        fontSize:"2vh",  
        fontWeight:"600",
        lineHeight:"100%",  
    };

    const TextModalStyleFactored = {
        display:"flex",
        flexDirection:"row",
        flexWrap:"wrap",
        paddingTop:"0.1vw",
        paddingBottom:"0.1vw",
        paddingLeft:"0.1vw",
        paddingRight:"0.1vw",
        alignItems:"center",
        justifyContent:"center",
        textAlign:"center",       
        width: "100%",        
        fontWeight: "500",
        lineHeight:"100%",
        background:"inherit",
        backgroundColor:"inherit",
        color:"inherit",  
        fontSize: "max(2vh,calc(1vh * " +factor+"))",
        letterSpacing: (factor <= 1) ? "0.3vw": "0.1vw",
    };

    const NormalFeatureStyleFactored = {
        display:"flex",        
        flexDirection:"column",
        flexWrap:"nowrap",
        order:(factor < 3) ? "0" :"1",
        margin:"0 auto",
        alignItems:"center",
        justifyContent:"center", 
        background:"inherit",
        backgroundColor:"inherit",
        color:"inherit",
        height:(factor <= 1) ? "35vh" 
                : (factor < 3) ? "35vh"
                : "75vh",
        maxWidth: (factor <= 1) ? "96vw" 
                : (factor < 3) ? "38vw"
                : "19vw",
        width:  (factor <= 1) ? "96vw"
                : (factor < 3) ? "38vw"
                : "19vw",
        paddingRight:(factor <=1) ? "0.25vw" :"0.25vw",
        paddingLeft:(factor <=1) ? "0.25vw" :"0.25vw",                  
    };


    const NormalPictureStyleFactored = {
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        textAlign:"center",
        alignSelf:"center",
        margin:"0 auto",
        padding:"0.1em",
        objectFit:"contain",
        backgroundSize:"contain",
        overflow:"hidden",       
        background:"inherit",
        backgroundColor:"inherit",
        color:"inherit",
        maxWidth: (factor <= 1) ? "0vw" 
                : (factor < 3) ? "58vw"
                : "58vw",
        width:  (factor <= 1) ? "0vw"
                : (factor < 3) ? (75*(4/3))+"vh"
                : (75*(4/3))+"vh",
    };

    const ModalContainerStyleFactored = {
        position: "absolute",
        display: "flex",
        flexWrap:"nowrap",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        alignSelf:"center",
        top: "5vh",
        left: "0vw",
        right: "0vw",
        overflow: "hidden",
        height:"81vh",
        width: "100vw",
        zIndex:"2",
        background:"rgb(0,0,0)",
        backgroundColor:"rgb(0,0,0)",
        color:"rgb(255,255,255)",
    };

    const ClientContainerStyleFactored = {
        position: "relative",
        display: "flex", 
        flexGrow:"1",       
        flexWrap:"nowrap",
        flexDirection:"column",
        margin: "0 auto",
        paddingBottom:"0.1vh",
        alignItems:"center",
        justifyContent:"center",
        minHeight:"76vh",
        height:"76vh",
        maxHeight:"76vh",    
        minWidth:"80vw",
        maxWidth:"100vw",
        objectFit: "contain",
        backgroundSize:"contain",
        overflow:"hidden",
        background:"inherit",
        backgroundColor:"inherit",
        color:"inherit",   
    };


    const MainPictureStyleFactored = {
        display:"flex",        
        alignItems:"center",
        justifyContent:"center",        
        alignSelf:"center",
        textAlign:"center",
        margin: "0 auto",
        padding:"0.1em",
        objectFit:"contain",
        backgroundSize:"contain",
        overflow:"hidden",        
        maxWidth:  (factor <= 1) ? "80vw"
                    : (factor < 3) ? "48vw" 
                    :"24vw",
        width:  (factor <= 1) ? "80vw"
                    : (factor < 3) ? (0.6*83)*(4/3)+"vh"
                    : (83)*(4/3)+"vh",
    };


    const ModalFeatureStyleFactored = {
        display: "flex",
        flexDirection:"column",
        flexWrap:"wrap",
        margin: "0 auto",
        paddingTop:"1vh",
        paddingBottom:"1vh",
        paddingLeft:"1vw",
        paddingRight:"1vw",
        textAlign:"center",
        alignItems:"center",
        justifyContent:"center", 
        height:"32vh",              
        minWidth:"80vw",
        width:"80vw",        
        objectFit:"contain",
        overflow:"hidden",
        background:"inherit",
        backgroundColor:"inherit",
        color:"inherit",    
    };


    const ModalPictureStyleFactored = {
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        alignSelf:"center",
        margin:"0 auto",
        padding:"0", 
        maxWidth:"80vw",      
        width:(44*4/3)+"vh",                
        objectFit:"contain",
        backgroundSize:"contain",
        overflow:"hidden",
        background:"inherit",
        backgroundColor:"inherit",
        color:"inherit",    
    };


    const ArrowContainerStyleFactored = {
        display: "flex",
        flexDirection:"column",
        flexWrap:"wrap",
        margin: "0 auto",        
        paddingTop:(factor <= 1) ? "35%" : "15%",
        paddingBottom:(factor <= 1) ? "45%" : "0",
        alignItems:"center",
        justifyContent:"center",
        alignSelf:"center",
        minWidth:"5vw",
        width:"auto",
        height:"auto",
        position: "relative",
        overflow:"hidden",
        fontSize:"3vh",
        fontWeight:"700",
        lineHeight:"5vh",
        background:"inherit",
        backgroundColor:"inherit",
        color:"inherit", 
    };



    const ClientArrowContainerStyleFactored = {
        display:"flex",
        flexWrap:"nowrap",
        flexDirection:"row",
        margin:"0 auto",
        padding:"0",
        alignItems:"center",
        justifyContent:"center",
        width:"100vw",
        height:"76vh",
        objectFit:"contain",
        backgroundSize:"contain",
        position:"relative",
        overflow:"hidden",
        background:"inherit",
        backgroundColor:"inherit",
        color:"inherit", 
    };


    const ImageContainerStyleFactored = {
        display:"flex",
        flexDirection:"row",
        flexWrap:"wrap",
        margin:"0 auto",
        alignItems:"center",
        justifyContent:"space-around",
        maxHeight:"75vh",        
        overflow:"hidden",
        height:(factor <= 1) ? "0%" :"75vh",
        width: (factor <= 1) ? "0%" : "58vw",
        maxWidth:(factor <= 1) ? "0%" : "58vw",
    };

    const SideContainerStyleFactored = {
        display:"flex",
        flexDirection:"row",
        flexWrap:"wrap",
        margin:"0 auto",
        alignItems:"center",
        justifyContent:"space-around",
        maxHeight:"75vh",
        height:"75vh",        
        overflow:"hidden",
        width:(factor <= 1) ? "96vw" : "38vw",
        maxWidth:(factor <= 1) ? "96vw" : "38vw",
    };


    const FooterStyleFactored = {
        display:"flex",
        flexDirection:"row", 
        flex:"0 1 auto", 
        justifyContent:"center",
        textAlign:"center",
        alignSelf:"center",
        paddingTop:"0.25vh", 
        paddingBottom:"0.75vh",       
        alignItems:"center",
        width:"98vw",
        height:"12vh",           
        maxHeight:"12vh",
        fontSize:"min(1.5vh,1.5vw)",
        fontWeight:"400",        
        letterSpacing:"0.2vw",
    };


    const ContentBottomStyleFactored = {
        display:"flex",
        flexDirection:"row",
        flexWrap:"nowrap",
        margin:"0 auto",
        paddingLeft:"1vw",
        paddingRight:"1vw",
        paddingTop:"1vh",
        paddingBottom:"1vh",
        alignItems:"center",
        justifyContent:"space-around",
        borderBottomLeftRadius:"0.6em",
        borderBottomRightRadius:"0.6em",        
        height:"77vh",
        maxHeight:"77vh", 
        width:"98vw",
        maxWidth:"98vw",
        overflow:"hidden",
    };

    const HeaderStyleFactored = {
        display:"flex",
        flexDirection:"row",
        justifyContent:"left",
        flex:"0 1 auto",        
        paddingTop:"0.75vh",
        paddingBottom:"0.25vh",
        paddingLeft:"1vw",
        paddingRight:"1vw",        
        alignItems:"center",
        width:"100vw",        
        maxHeight:"5vh",
        height:"5vh",
    };


    const LogoStyleFactored = {
        display: "flex",
        flexDirection:"row",
        justifyContent:"flex-start",
        alignItems:"flex-start",
        paddingLeft:"0.1vw",
        paddingRight:"0.1vw",
        paddingTop:"0.1vh",
        paddingBottom:"0.1vh",
        margin:"0",
        width:"min(4vh,4vw)",
        maxHeight:"4vh",
        maxWidth:"5%",
    };


    const SiteTitleStyleFactored = {
        display:"flex",
        flexDirection:"row",
        justifyContent:"flex-start",        
        alignItems:"center",
        paddingLeft:"0.1vw",
        paddingRight:"0.1vw",
        margin:"0",
        height:"4vh",
        maxHeight:"4vh",
        width:"35%",
        maxWidth:"35%",
        fontSize:"min(2vh,2vw)",
        fontWeight: "400",
    };

    const SiteDescriptionStyleFactored = {
        display:"flex",
        flexDirection:"row",
        flexWrap:"wrap",
        justifyContent:"flex-end",
        textAlign:"right",
        alignItems:"center",
        padding:"0",
        margin:"0",
        height:"4vh",
        maxHeight:"4vh",
        width:"60%",
        maxWidth:"60%",
        fontSize:"min(1.5vh,1.5vw)",
        fontWeight:"400",  
    };


    const MainContainerStyleFactored = {
        display:"flex",
        flexGrow:"1",
        flexDirection:"column",
        margin:"0 auto",
        paddingLeft:"1vw",
        paddingRight:"1vw",
        alignItems:"center",
        justifyContent:"center",
        width:"100vw",
        minWidth:"100vw",
        minHeight:"81vh",
        height:"81vh",
        overflow:"hidden",
    };

    const AppContainerStyleFactored = {
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        width:"100vw",
        height:"98vh",
    };

    const ModalImageStyleFactored={
        background:"inherit",
        backgroundColor:"inherit",
        overflow:"hidden",
        maxWidth:"90vw",
        width:(76*(4/3))+"vh",
    };
  
      const StyleAll = 
        {
            "factor":factor,
            "ButtonTitleFontStyle":ButtonTitleFontStyleFactored,
            "BodyTitleFontStyle":BodyTitleFontStyleFactored,
            "ButtonSectionStyle":ButtonSectionStyleFactored, 
            "ButtonSectionStyle2":ButtonSectionStyle2Factored,
            "ButtonContainerStyle":ButtonContainerStyleFactored,
            "CloseContainerStyle":CloseContainerStyleFactored,
            "CloseIconStyle":CloseIconStyleFactored,            
            "TextSectionStyle":TextSectionStyleFactored,
            "TextNormalStyle":TextNormalStyleFactored,
            "BodyHeadStyle":BodyHeadStyleFactored,        
            "TextModalStyle":TextModalStyleFactored,
            "NormalFeatureStyle":NormalFeatureStyleFactored,
            "NormalPictureStyle":NormalPictureStyleFactored,
            "ModalContainerStyle":ModalContainerStyleFactored,
            "ClientContainerStyle":ClientContainerStyleFactored,
            "MainPictureStyle":MainPictureStyleFactored,
            "ModalFeatureStyle":ModalFeatureStyleFactored,
            "ModalPictureStyle":ModalPictureStyleFactored,
            "ArrowContainerStyle":ArrowContainerStyleFactored,
            "ClientArrowContainerStyle":ClientArrowContainerStyleFactored,
            "ImageContainerStyle":ImageContainerStyleFactored,
            "SideContainerStyle":SideContainerStyleFactored,
            "FooterStyle":FooterStyleFactored,
            "ContentBottomStyle":ContentBottomStyleFactored,
            "HeaderStyle":HeaderStyleFactored,
            "LogoStyle":LogoStyleFactored,
            "SiteTitleStyle":SiteTitleStyleFactored,
            "SiteDescriptionStyle":SiteDescriptionStyleFactored,
            "MainContainerStyle":MainContainerStyleFactored,
            "AppContainerStyle":AppContainerStyleFactored,
            "ModalImageStyle":ModalImageStyleFactored,            
          };

       
  
    
    return StyleAll;
      
  };
  
 
  
  