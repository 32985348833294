import React from 'react';

import { FooterContainer } from '../../global.css';




const Footer = () =>{ 
  
  const footerStyle = {
    display:"flex",
    flexDirection:"row", 
    flex:"0 1 auto", 
    justifyContent:"center",
    textAlign:"center",
    alignSelf:"center",
    paddingTop:"0.25vh", 
    paddingBottom:"0.75vh",       
    alignItems:"flex-start",
    width:"98vw",
    height:"12vh",           
    maxHeight:"12vh",
    fontSize:"min(3vh,3vw)",
    fontWeight:"700",        
    letterSpacing:"0.2vw", 
    color:"rgb(0,100,0)",
};

  
  return(
    <FooterContainer style={footerStyle}>
      
      <address>Contact : consultant@ponnisoft.com</address>        
      
      
    </FooterContainer>
  
)};



export default Footer;
