import React ,{useContext} from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {GatsbyImage,getImage} from 'gatsby-plugin-image';
import { NormalPicture, CloseContainer, ClientContainer, ModalContainer } from "../../global.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes} from "@fortawesome/free-solid-svg-icons";

import { BreakpointContext } from '../../providers/breakpoint';

const NormalMonitor = (props) => {

  const styleAll = useContext(BreakpointContext);
  const modalContainerStyle = styleAll['ModalContainerStyle'];
  const closeContainerStyle  = styleAll['CloseContainerStyle'];
  const closeIconStyle = styleAll['CloseIconStyle']; 
  const clientContainerStyle  = styleAll['ClientContainerStyle'];
  const normalPictureStyle = styleAll['NormalPictureStyle'];
  
  const imageStyle = styleAll['ModalImageStyle'];

const dataNormalMonitor = useStaticQuery(graphql`
query dataNormalMonitorQuery {
  file(relativePath: {eq: "img_monitor.png"}) {
      childImageSharp {
      gatsbyImageData(pngOptions: {quality: 80}, placeholder: BLURRED)
    }
  }
}

  
`);

const image = getImage(dataNormalMonitor.file.childImageSharp.gatsbyImageData);


const OnDoubleClk = (event) => {
props.OnDoubleClick();
event.preventDefault();
}

    return (
        <>
                        
          <NormalPicture 
              key="normalpicturemonitor"
              initial={{ opacity: 0, x: 0, y: -20 }}
              animate={{ opacity: 1, x: 0, y: 0 }}
              exit={{ opacity: 0, x: 20, y: 0 }}
              transition={{
              type: "spring",
              mass: 0.5,
              stiffness: 50,
              duration:0.5,
              }}
              style={normalPictureStyle}
            >
              <GatsbyImage image={image} onDoubleClick={OnDoubleClk} style={{height:"100%"}} alt="CCTV-Remote-Monitor-Multilocations-Image"/>
            </NormalPicture>

          {
            props.largeImage ? 
          
          
            <ModalContainer
              key="modalimagenormal"
              initial={{ opacity: 0, x: -200 , y: -200}}
              animate={{ opacity: 1, x: 0 , y: 0}}
              exit={{ opacity: 0, x: 200, y: 200 }}
              transition={{
              type: "spring",
              mass: 0.25,
              stiffness: 50,
              duration:0.5,
              }}
              style = {modalContainerStyle}
              onDoubleClick={OnDoubleClk} 
            >
              <CloseContainer style = {closeContainerStyle}>
                <FontAwesomeIcon icon={faTimes} style={closeIconStyle} onClick = {OnDoubleClk} />
              </CloseContainer>
              <ClientContainer style={clientContainerStyle}>            
              <GatsbyImage image={image} style={imageStyle} alt="CCTV-Remote-Monitor-Multilocations-Image" />
              </ClientContainer>
            </ModalContainer>
            
            : null
          }

          

        </>
    )
};

export default NormalMonitor;