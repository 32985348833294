import React, {useContext} from 'react'
import { useStaticQuery, graphql } from 'gatsby';
import Head from '../head/head';
import Header from '../header/header';
import Footer from '../footer/footer';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from '../../global.css';
import theme from '../../themes/theme';

import { AppContainer, MainContainer } from '../../global.css';
import { BreakpointContext } from '../../providers/breakpoint';

export default function App({children}) {

  const styleAll = useContext(BreakpointContext);
  const appContainerStyle = styleAll['AppContainerStyle'];
  
  const mainContainerStyle = styleAll['MainContainerStyle'];

    const data = useStaticQuery(graphql`
    query ssrQuery {
      site {
        siteMetadata {
          siteTitle,
          siteDescription
        }
      }
    }
  `);

    return (
      <ThemeProvider theme = {theme}>
      <GlobalStyle/>
            <AppContainer style={appContainerStyle}>
              <Head />
              <Header 
              title={data.site.siteMetadata.siteTitle} 
              description={data.site.siteMetadata.siteDescription}        
              />
              <MainContainer style = {mainContainerStyle}>
                
                  {children}
              
              </MainContainer>
              <Footer/>
            </AppContainer> 
    </ThemeProvider>      
      )
        
    
}
