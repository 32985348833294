
import React,{useState, useEffect, useContext, useRef} from 'react';

import { BodyHead, TitleSection } from '../../global.css';
import { BreakpointContext } from '../../providers/breakpoint';



export const FeatureTitleStorage = (props) => {

  
  

  const styleAll = useContext(BreakpointContext);
  const TitleSectionStyle = styleAll['ButtonSectionStyle']; 
  const titleFontStyle = styleAll['ButtonTitleFontStyle'];
  

  const [monitorStyle, setMonitorStyle] = useState(TitleSectionStyle);

  
  const TitleStyleFocus = {...monitorStyle, border : "0.1em solid darkGreen",boxShadow:"0 0 0.1em 0.1em lightGreen",};
  const TitleStyleBlur = {...monitorStyle, border : "0.1em solid lightGray",boxShadow:"none",};
  const TitleStyleHover = {...monitorStyle, border : "0.1em solid lightGreen",};
  const TitleStyleLeave = {...monitorStyle, border : "0.1em solid lightGray",};

  const OnMouseOver = (event) => {
    setMonitorStyle(TitleStyleHover);
    event.preventDefault();
   }
   const OnFocus = (event) => {
     OnClicked();
     setMonitorStyle(TitleStyleFocus);
     event.preventDefault();
    }
    const OnBlur = (event) => {    
     setMonitorStyle(TitleStyleBlur);
     event.preventDefault();
    }
    const OnMouseLeave = (event) => {     
       setMonitorStyle(TitleStyleLeave);
       event.preventDefault();     
    }
    const OnClicked = () =>{
     props.onClicked("title-3");
    
   } 
   
    return (
      <TitleSection 
      key="title-3" 
      whileHover={{ scale: 1.05, opacity: 1 }} 
      whileTap={{ scale: 0.95 }} 
      tabIndex="0"                       
      onMouseOver={OnMouseOver}
      onMouseLeave={OnMouseLeave} 
      onFocus={OnFocus}     
      onBlur={OnBlur}      
      style={monitorStyle}  
      >  
        
        <h1 style={titleFontStyle}>CCTV Health Update</h1>
            
      </TitleSection>
    )
};

export const FeatureTitleInstant = (props) => {  
  
  const styleAll = useContext(BreakpointContext);
  const TitleSectionStyle = styleAll['ButtonSectionStyle']; 
  const titleFontStyle = styleAll['ButtonTitleFontStyle'];
  
  



  const [monitorStyle, setMonitorStyle] = useState(TitleSectionStyle);

  
  const TitleStyleFocus = {...monitorStyle, border : "0.1em solid darkGreen",boxShadow:"0 0 0.1em 0.1em lightGreen",};
  const TitleStyleBlur = {...monitorStyle, border : "0.1em solid lightGray",boxShadow:"none",};
  const TitleStyleHover = {...monitorStyle, border : "0.1em solid lightGreen",};
  const TitleStyleLeave = {...monitorStyle, border : "0.1em solid lightGray",};

  const OnMouseOver = (event) => {
    setMonitorStyle(TitleStyleHover);
    event.preventDefault();
   }
   const OnFocus = (event) => {
     OnClicked();
     setMonitorStyle(TitleStyleFocus);
     event.preventDefault();
    }
    const OnBlur = (event) => {    
     setMonitorStyle(TitleStyleBlur);
     event.preventDefault();
    }
    const OnMouseLeave = (event) => {     
       setMonitorStyle(TitleStyleLeave);
       event.preventDefault();     
    }
    const OnClicked = () =>{
     props.onClicked("title-6");     
   } 
  

  return (
    <TitleSection 
    key="title-6" 
    whileHover={{ scale: 1.05, opacity: 1 }} 
    whileTap={{ scale: 0.95 }} 
    tabIndex="0"   
    onMouseOver={OnMouseOver}
    onMouseLeave={OnMouseLeave}
    onFocus={OnFocus}
    onBlur={OnBlur}
    style={monitorStyle}
    >  
      
      <h1 style={titleFontStyle}>Action On Alerts</h1>
          
      </TitleSection>
  )
};

export const FeatureTitleMonitor = (props) => {

  const styleAll = useContext(BreakpointContext);
  const TitleSectionStyle = styleAll['ButtonSectionStyle']; 
  const titleFontStyle = styleAll['ButtonTitleFontStyle'];
  
  


  const [monitorStyle, setMonitorStyle] = useState(TitleSectionStyle);
  
  const TitleStyleFocus = {...monitorStyle, border : "0.1em solid darkGreen",boxShadow:"0 0 0.1em 0.1em lightGreen",};
  const TitleStyleBlur = {...monitorStyle, border : "0.1em solid lightGray",boxShadow:"none",};
  const TitleStyleHover = {...monitorStyle, border : "0.1em solid lightGreen",};
  const TitleStyleLeave = {...monitorStyle, border : "0.1em solid lightGray",};
  

  const OnMouseOver = (event) => {
    setMonitorStyle(TitleStyleHover);
    event.preventDefault();
   }
   const OnFocus = (event) => {
     OnClicked();
     setMonitorStyle(TitleStyleFocus);
     event.preventDefault();
    }
    const OnBlur = (event) => {    
     setMonitorStyle(TitleStyleBlur);
     event.preventDefault();
    }
    const OnMouseLeave = (event) => {     
       setMonitorStyle(TitleStyleLeave);
       event.preventDefault();     
    }
    const OnClicked = () =>{
     props.onClicked("title-2");
    
   } 

  
  return (
    <TitleSection 
    key="title-2" 
    whileHover={{ scale: 1.05, opacity: 1 }} 
    whileTap={{ scale: 0.95 }}     
    tabIndex="0"     
    onMouseOver={OnMouseOver}
    onMouseLeave={OnMouseLeave}
    onFocus={OnFocus}
    onBlur={OnBlur}
    style={monitorStyle}    
    >  
      
      <h1 style={titleFontStyle}>Multi CCTV Videos</h1>
          
    </TitleSection>
  )
};

export const FeatureTitleOptions = (props) => {

  const styleAll = useContext(BreakpointContext);
  const TitleSectionStyle = styleAll['ButtonSectionStyle']; 
  const titleFontStyle = styleAll['ButtonTitleFontStyle'];
 


  const [monitorStyle, setMonitorStyle] = useState(TitleSectionStyle);

  
  const TitleStyleFocus = {...monitorStyle, border : "0.1em solid darkGreen",boxShadow:"0 0 0.1em 0.1em lightGreen",};
  const TitleStyleBlur = {...monitorStyle, border : "0.1em solid lightGray",boxShadow:"none",};
  const TitleStyleHover = {...monitorStyle, border : "0.1em solid lightGreen",};
  const TitleStyleLeave = {...monitorStyle, border : "0.1em solid lightGray",};

  const OnMouseOver = (event) => {
    setMonitorStyle(TitleStyleHover);
    event.preventDefault();
   }
   const OnFocus = (event) => {
     OnClicked();
     setMonitorStyle(TitleStyleFocus);
     event.preventDefault();
    }
    const OnBlur = (event) => {    
     setMonitorStyle(TitleStyleBlur);
     event.preventDefault();
    }
    const OnMouseLeave = (event) => {     
       setMonitorStyle(TitleStyleLeave);
       event.preventDefault();     
    }
    const OnClicked = () =>{
     props.onClicked("title-5");     
   } 

 

  return (
    <TitleSection 
    key="title-5" 
    whileHover={{ scale: 1.05, opacity: 1 }} 
    whileTap={{ scale: 0.95 }} 
    tabIndex="0"
    onMouseOver={OnMouseOver}
    onMouseLeave={OnMouseLeave}
    onFocus={OnFocus}
    onBlur={OnBlur}
    style={monitorStyle}
    > 
      
      <h1 style={titleFontStyle}>Footage Download</h1>
          
    </TitleSection>
  )
};

export const FeatureTitleAlert = (props) => {

  const styleAll = useContext(BreakpointContext);
  const TitleSectionStyle = styleAll['ButtonSectionStyle']; 
  const titleFontStyle = styleAll['ButtonTitleFontStyle'];
 

  const [monitorStyle, setMonitorStyle] = useState(TitleSectionStyle);

  
  const TitleStyleFocus = {...monitorStyle, border : "0.1em solid darkGreen",boxShadow:"0 0 0.1em 0.1em lightGreen",};
  const TitleStyleBlur = {...monitorStyle, border : "0.1em solid lightGray",boxShadow:"none",};
  const TitleStyleHover = {...monitorStyle, border : "0.1em solid lightGreen",};
  const TitleStyleLeave = {...monitorStyle, border : "0.1em solid lightGray",};

  const OnMouseOver = (event) => {
   setMonitorStyle(TitleStyleHover);
   event.preventDefault();
  }
  const OnFocus = (event) => {
    OnClicked();
    setMonitorStyle(TitleStyleFocus);
    event.preventDefault();
   }
   const OnBlur = (event) => {    
    setMonitorStyle(TitleStyleBlur);
    event.preventDefault();
   }
   const OnMouseLeave = (event) => {     
      setMonitorStyle(TitleStyleLeave);
      event.preventDefault();     
   }
   const OnClicked = () =>{
    props.onClicked("title-4");    
  } 

  

  return (
    <TitleSection 
    key="title-4" 
    whileHover={{ scale: 1.05, opacity: 1 }} 
    whileTap={{ scale: 0.95 }} 
    tabIndex="0"
    onMouseOver={OnMouseOver}
    onMouseLeave={OnMouseLeave}
    onFocus={OnFocus}
    onBlur={OnBlur}
    style={monitorStyle}
    >  
      
      <h1 style={titleFontStyle}>Alert Video Popup</h1>
          
    </TitleSection>
  )
};

export const FeatureTitleMain = (props) => {
  
  const styleAll = useContext(BreakpointContext);
  const TitleSectionStyle = styleAll['ButtonSectionStyle']; 
  const titleFontStyle = styleAll['ButtonTitleFontStyle'];
  const factor = styleAll['factor'];
  
  const [monitorStyle, setMonitorStyle] = useState(TitleSectionStyle);

 
  const TitleStyleFocus = {...monitorStyle, border : "0.1em solid darkGreen",boxShadow:"0 0 0.1em 0.1em lightGreen",};
  const TitleStyleBlur = {...monitorStyle, border : "0.1em solid lightGray",boxShadow:"none",};
  const TitleStyleHover = {...monitorStyle, border : "0.1em solid lightGreen",};
  const TitleStyleLeave = {...monitorStyle, border : "0.1em solid lightGray",};
  
  const focusTitle1 = useRef(null);

  const OnMouseOver = (event) => {
    setMonitorStyle(TitleStyleHover);
    event.preventDefault();
   }
   const OnFocus = (event) => {
     OnClicked();
     setMonitorStyle(TitleStyleFocus);
     event.preventDefault();
    }
    const OnBlur = (event) => {    
     setMonitorStyle(TitleStyleBlur);
     event.preventDefault();
    }
    const OnMouseLeave = (event) => {     
       setMonitorStyle(TitleStyleLeave);
       event.preventDefault();     
    }
    const OnClicked = () =>{
     props.onClicked("title-1");
     
   } 

   
   useEffect( () => {
       
    const TriggerModal = (ftr) => {             
          if(ftr >1){
            if(focusTitle1.current){ 
              focusTitle1.current.focus();
              
            }                         
          }else{
            if(focusTitle1.current){ 
              focusTitle1.current.blur();
              
            }          
           }            
      }    
     TriggerModal(factor);
   
    },[factor]);

  
  return (
    <TitleSection 
    key="title-1" 
    whileHover={{ scale: 1.05, opacity: 1 }} 
    whileTap={{ scale: 0.95 }} 
    tabIndex="0"
    ref={focusTitle1}      
    onMouseOver={OnMouseOver}
    onMouseLeave={OnMouseLeave}
    onFocus={OnFocus}
    onBlur={OnBlur}    
    style={monitorStyle}
    >  
      
      <h1 style={titleFontStyle}>Multimaster</h1>
          
      </TitleSection>
  )
};

export const BodyTitle = () => {

  const styleAll = useContext(BreakpointContext); 

const bodyHeadStyle = styleAll['BodyHeadStyle'];
const titleFontStyle = styleAll['BodyTitleFontStyle'];

  return (
       
      <BodyHead style={bodyHeadStyle}> 
        <h1 style={titleFontStyle}>Windows App</h1>
      </BodyHead>   
      
  )
};

