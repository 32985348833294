import React,{useContext, useState, useEffect} from "react";




import { ContentBottom} from '../global.css';
import { AnimatePresence} from "framer-motion";

import { BreakpointContext } from "../providers/breakpoint";
import { BodyTitle } from "../constants/content/featureTitle";

import ModalTitleClick from "../components/Modal/modalTitleClick";
import NormalTitleClick from "../components/Modal/normalTitleClick";



const Home = () => {  

  const styleAll = useContext(BreakpointContext);  

  const contentBottomStyle  = styleAll['ContentBottomStyle'];
  
  const factor = styleAll['factor'];

  const [title,setTitle] = useState("");

  const [modalMainImage,setModalMainImage] = useState(false);
  const [modalNormalImage,setModalNormalImage] = useState(false);
  const [modalSlider,setModalSlider] = useState(false);

  
  

  const OnMainDoubleClk = () => {
    
    setModalMainImage(!modalMainImage);
  } 
  const OnNormalDoubleClk = () => {
    
    setModalNormalImage(!modalNormalImage);
  } 
  

  const ImageLoader = (str) => {
    if(str === "close"){
      
      if(modalSlider){
        setModalSlider(false);
      }
      setTitle("");
      
    }else if(str === "left"){
  

      if(title === "title-1"){
        setTitle("title-6");
      }else if(title === "title-2"){
        setTitle("title-1");
      }else if(title === "title-3"){
        setTitle("title-2");
      }else if(title === "title-4"){
        setTitle("title-3");
      }else if(title === "title-5"){
        setTitle("title-4");
      }else if(title === "title-6"){
        setTitle("title-5");
      }

      if(!modalSlider){
        setModalSlider(true);
      }

    }else if(str === "right"){
 

      if(title === "title-1"){
        setTitle("title-2");
      }else if(title === "title-2"){
        setTitle("title-3");
      }else if(title === "title-3"){
        setTitle("title-4");
      }else if(title === "title-4"){
        setTitle("title-5");
      }else if(title === "title-5"){
        setTitle("title-6");
      }else if(title === "title-6"){
        setTitle("title-1");
      } 

      if(!modalSlider){
        setModalSlider(true);
      }

    }else{
     
        setTitle(str)
      
      if(!modalSlider){
        setModalSlider(true);
      }
    }
    
  }



  useEffect(()=>{
        
    
  if(factor <= 1){
    

    if((!modalSlider)&&(title !=="")){
      setTitle("");
    }

    if(modalNormalImage){
      setModalNormalImage(!modalNormalImage);
    }

  }else{
    if(modalSlider){
      setModalSlider(false);
    }
    
    if(title ===""){
      setTitle("title-1");
    }
    
    
    
  }

  },[factor,title,modalSlider,modalMainImage,modalNormalImage]);


  return (   
  
    <>      
      <BodyTitle/>          
        <ContentBottom 
        key="contentbottom"
        style={contentBottomStyle}
        >  
        <AnimatePresence initial={false}>                             
              <ModalTitleClick 
                  key="modalTitleClick"
                  title={title} 
                  OnModalMainDoubleClick={OnMainDoubleClk} 
                  largeImage={modalMainImage}           
                  imageLoader={()=>{ImageLoader("close")}} 
                  imageSliderLeft={()=>{ImageLoader("left")}} 
                  imageSliderRight={()=>{ImageLoader("right")}}
                  modalSlider={modalSlider} 
                  OnMenuClicked ={(menuTitle)=>ImageLoader(menuTitle)}
              />                  
              <NormalTitleClick
                    key="normalTitleClick"
                    title={title}
                    OnDoubleClick={OnNormalDoubleClk}
                    largeImage={modalNormalImage}
              />                       
        </AnimatePresence>
        </ContentBottom>          
    </>           
   )
};



export default Home;
