import React,{useContext} from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {GatsbyImage,getImage} from 'gatsby-plugin-image';
import { ModalFeature, ModalPicture } from '../../global.css';
import { BreakpointContext } from "../../providers/breakpoint";
import { FeatureInstantModal } from '../content/features';

const ModalInstant = () => {

  const styleAll = useContext(BreakpointContext);
  const modalPictureStyle = styleAll['ModalPictureStyle'];
  const modalFeatureStyle  = styleAll['ModalFeatureStyle'];

  const imageStyle = styleAll['ModalImageStyle'];
    const dataModalInstant = useStaticQuery(graphql`
    query dataModalInstantQuery {
        file(relativePath: {eq: "img_instant.png"}) {
            childImageSharp {
            gatsbyImageData(pngOptions: {quality: 80},placeholder: BLURRED)
          }
        }
      }
      
        
      `);
      
      const image = getImage(dataModalInstant.file.childImageSharp.gatsbyImageData);
      
      const ftr = {
        hidden: { opacity: 0},
        show: {
          opacity: 1 ,
        transition:{ 
          type: "spring",
          mass: 0.25,
          stiffness: 75,
          duration:1,
          staggerChildren:0.5,                   
          delayChildren:0.5,
            
          }
        },
       
      };
    
    
        return (
          <>
          <ModalPicture
          key="modalpictureinstant"
          style={modalPictureStyle}
          >
            <GatsbyImage image={image} style={imageStyle} alt="CCTV-Remote-Alert-Action-Image" />
          </ModalPicture>
          <ModalFeature
          key="modalfeatureinstant"         
          variants={ftr}
          initial="hidden"
          animate="show"
          style={modalFeatureStyle}
          >
            <FeatureInstantModal/>
          </ModalFeature>
        </>
        )
};
export default ModalInstant;