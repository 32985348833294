import React,{useContext} from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {GatsbyImage,getImage} from 'gatsby-plugin-image';
import { ModalFeature, ModalPicture } from '../../global.css';
import { BreakpointContext } from "../../providers/breakpoint";
import { FeatureMonitorModal } from '../content/features';

const ModalMonitor = () => {

  const styleAll = useContext(BreakpointContext);
  const modalPictureStyle = styleAll['ModalPictureStyle'];
  const modalFeatureStyle  = styleAll['ModalFeatureStyle'];
  const imageStyle = styleAll['ModalImageStyle'];

const dataModalMonitor = useStaticQuery(graphql`
query dataModalMonitorQuery {
  file(relativePath: {eq: "img_monitor.png"}) {
      childImageSharp {
      gatsbyImageData(pngOptions: {quality: 80},placeholder: BLURRED)
    }
  }
}

  
`);

const image = getImage(dataModalMonitor.file.childImageSharp.gatsbyImageData);

const ftr = {
  hidden: { opacity: 0},
  show: {
    opacity: 1 ,
  transition:{ 
    type: "spring",
    mass: 0.25,
    stiffness: 75,
    duration:1,
    staggerChildren:0.5,                   
    delayChildren:0.5,
      
    }
  },
 
};


    return (
      <>
      <ModalPicture
      key="modalpicturemonitor"
      style={modalPictureStyle}
      >
        <GatsbyImage image={image} style={imageStyle} alt="CCTV-Remote-Monitor-Multilocations-Image" />
      </ModalPicture>
      <ModalFeature
      key="modalfeaturemonitor"
      variants={ftr}
      initial="hidden"
      animate="show"
      style={modalFeatureStyle}
      >
        <FeatureMonitorModal/>
      </ModalFeature>
    </>
    )
};

export default ModalMonitor;