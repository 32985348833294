import React,{useContext} from 'react';
import { TextModal, TextSection } from '../../global.css';

import { BreakpointContext } from '../../providers/breakpoint';

const txt = {
    hidden: { opacity: 0, y: -100 },
    show: { opacity: 1, y: 0 },
    
  };

export const FeatureAlertNormal = () => {
    const styleAll = useContext(BreakpointContext);
    const textSectionStyle = styleAll['TextSectionStyle'];
    const textNormalStyle = styleAll['TextNormalStyle'];
    
   
    return (
        <>
        <TextSection key="falerttextnormal1" variants={txt} style={textSectionStyle}> 
            <h1 style={textNormalStyle}>Alert  window  pop-up  on  events.</h1> 
        </TextSection>
        <TextSection key="falerttextnormal2" variants={txt} style={textSectionStyle}>    
            <h1 style={textNormalStyle}>Video  pop-up  on  alerts.</h1>
       </TextSection>
       <TextSection key="falerttextnormal3" variants={txt} style={textSectionStyle}>    
            <h1 style={textNormalStyle}>Alert  pop-up  near  the  taskbar.</h1>
        </TextSection>
        </>
        
        
    )
};

export const FeatureStorageNormal = () => {
    const styleAll = useContext(BreakpointContext);
    const textSectionStyle = styleAll['TextSectionStyle'];
    const textNormalStyle = styleAll['TextNormalStyle'];


    return (
        <>
        <TextSection key="fstortextnormal1" variants={txt} style={textSectionStyle}> 
            <h1 style={textNormalStyle}>Harddisk  data  from  all  locations.</h1> 
        </TextSection>
        <TextSection key="fstortextnormal2" variants={txt} style={textSectionStyle}>    
            <h1 style={textNormalStyle}>Recording  availability  in  number  of  days.</h1>
       </TextSection>
       <TextSection key="fstortextnormal3" variants={txt} style={textSectionStyle}>    
            <h1 style={textNormalStyle}>DVR/NVR  details  in  each  location.</h1>
        </TextSection>
        </>   
    )
};

export const FeatureMonitorNormal = () => {              
    const styleAll = useContext(BreakpointContext);
    const textSectionStyle = styleAll['TextSectionStyle'];
    const textNormalStyle = styleAll['TextNormalStyle'];
    
  
    return (
        <>
        <TextSection key="fmontextnormal1" variants={txt} style={textSectionStyle}> 
            <h1 style={textNormalStyle} >Monitor from Laptop from home or office.</h1> 
        </TextSection>
        <TextSection key="fmontextnormal2" variants={txt} style={textSectionStyle}>    
            <h1 style={textNormalStyle}>Monitor any location instantly on a single click.</h1>
       </TextSection>
       <TextSection key="fmontextnormal3" variants={txt} style={textSectionStyle}>    
            <h1 style={textNormalStyle}>Monitor locations of interest grouped together.</h1>
        </TextSection>
        </>
    )
};

export const FeatureOptionsNormal = () => {
    const styleAll = useContext(BreakpointContext);
    const textSectionStyle = styleAll['TextSectionStyle'];
    const textNormalStyle = styleAll['TextNormalStyle'];
    
    return (
        <>
        <TextSection key="fopttextnormal1" variants={txt} style={textSectionStyle}> 
            <h1 style={textNormalStyle}>Options  to  select cameras  for  video  monitoring.</h1> 
        </TextSection>
        <TextSection key="fopttextnormal2" variants={txt} style={textSectionStyle}>    
            <h1 style={textNormalStyle}>Options  to  monitor  locations  at  cyclic  interval.</h1>
       </TextSection>
       <TextSection key="fopttextnormal3" variants={txt} style={textSectionStyle}>    
            <h1 style={textNormalStyle}> Options  for  quick  playback  /  download of recording.</h1>
        </TextSection>
        </>
        
        
    )
};

export const FeatureInstantNormal = () => {
    const styleAll = useContext(BreakpointContext);
    const textSectionStyle = styleAll['TextSectionStyle'];
    const textNormalStyle = styleAll['TextNormalStyle'];
    

    return (
        <>
        <TextSection key="finstextnormal1" variants={txt} style={textSectionStyle}> 
            <h1 style={textNormalStyle}>Acknowledge  and  act  on  events.</h1> 
        </TextSection>
        <TextSection key="finstextnormal2" variants={txt} style={textSectionStyle}>    
            <h1 style={textNormalStyle}>Auto  record  video  on  events.</h1>
       </TextSection>
       <TextSection key="finstextnormal3" variants={txt} style={textSectionStyle}>    
            <h1 style={textNormalStyle}>Remote  control  sirens  and  lights.</h1>
        </TextSection>
        </>
        
        
    )
};

export const FeatureMainNormal = () => {              
    const styleAll = useContext(BreakpointContext);
    const textSectionStyle = styleAll['TextSectionStyle'];
    const textNormalStyle = styleAll['TextNormalStyle'];
    
  
    return (
        <>
        <TextSection key="fmaintextnormal1" variants={txt} style={textSectionStyle}> 
            <h1 style={textNormalStyle} >Ideal  For  CEOs,  Operations,  Logistics,  Regional  Heads.</h1> 
        </TextSection>
        <TextSection key="fmaintextnormal2" variants={txt} style={textSectionStyle}>    
            <h1 style={textNormalStyle}>Monitor  up  to  100  CCTV  locations  simultaneously.</h1>
       </TextSection>
       <TextSection key="fmaintextnormal3" variants={txt} style={textSectionStyle}>    
            <h1 style={textNormalStyle}>Quick  loading  of  videos  from  all  locations.</h1>
        </TextSection>
        </>
    )
};

export const FeatureAlertModal = () => {    
  
    const styleAll = useContext(BreakpointContext);
    
    const textModalStyle = styleAll['TextModalStyle'];
   
    return (
        <>
        <TextModal key="fttextmodal1" variants={txt} style={textModalStyle}> 
            Alert window pop-up on events.
        </TextModal>
        <TextModal key="fttextmodal2" variants={txt} style={textModalStyle}>    
            Video pop-up on alerts.        
        </TextModal>
        <TextModal key="fttextmodal3" variants={txt} style={textModalStyle}>
            Alert pop-up near the taskbar.
        </TextModal>
        </>
        
    )
};

export const FeatureStorageModal = () => {

    const styleAll = useContext(BreakpointContext);
    
    const textModalStyle = styleAll['TextModalStyle'];
    
    return (
        <>
        <TextModal key="fstextmodal1" variants={txt} style={textModalStyle}> 
        Harddisk data from all locations.
        </TextModal>
        <TextModal key="fstextmodal2" variants={txt} style={textModalStyle}>    
        Recording availability in number of days.        
        </TextModal>
        <TextModal key="fstextmodal3" variants={txt} style={textModalStyle}>
        DVR/NVR details in each location.
        </TextModal>
        </>
        
    )
};

export const FeatureMonitorModal = () => {

    const styleAll = useContext(BreakpointContext);
    
    const textModalStyle = styleAll['TextModalStyle'];
   

    return (
        <>
        <TextModal key="fmtextmodal1" variants={txt} style={textModalStyle}> 
            Monitor from Laptop from home or office.
        </TextModal>
        <TextModal key="fmtextmodal2" variants={txt} style={textModalStyle}>    
            Monitor any location instantly on a single click.        
        </TextModal>
        <TextModal key="fmtextmodal3" variants={txt} style={textModalStyle}>
            Monitor locations of interest grouped together.
        </TextModal>       
        </>        
    )
};

export const FeatureOptionsModal = () => {

    const styleAll = useContext(BreakpointContext);
    
    const textModalStyle = styleAll['TextModalStyle'];
    
    
    return (
        <>
        <TextModal key="fotextmodal1" variants={txt} style={textModalStyle}> 
        Options  to  select cameras  for  video  monitoring.
        </TextModal>
        <TextModal key="fotextmodal2" variants={txt} style={textModalStyle}>    
            Options to monitor locations at cyclic interval.        
        </TextModal>
        <TextModal key="fotextmodal3" variants={txt} style={textModalStyle}>
            Options for quick playback / download of recording.
        </TextModal>          
        </>        
    )
};

export const FeatureInstantModal = () => {

    const styleAll = useContext(BreakpointContext);
    
    const textModalStyle = styleAll['TextModalStyle'];
       
  

    return (
        <>
        <TextModal key="fitextmodal1" variants={txt} style={textModalStyle}> 
            Acknowledge and act on events.
        </TextModal>
        <TextModal key="fitextmodal2" variants={txt} style={textModalStyle}>    
            Auto record video on events.        
        </TextModal>
        <TextModal key="fitextmodal3" variants={txt} style={textModalStyle}>
            Remote control sirens and lights.
        </TextModal>       
        </>
    )
};

export const FeatureMainModal = () => {              
    const styleAll = useContext(BreakpointContext);
    
    const textModalStyle = styleAll['TextModalStyle'];
    
 
    return (
        <>
        <TextModal key="fmaintextmodal1" variants={txt} style={textModalStyle}> 
            Ideal  For  CEOs,  Operations,  Logistics,  Regional  Heads. 
        </TextModal>
        <TextModal key="fmaintextmodal2" variants={txt} style={textModalStyle}>    
            Monitor  up  to  100  CCTV  locations  simultaneously.
       </TextModal>
       <TextModal key="fmaintextmodal3" variants={txt} style={textModalStyle}>    
            Quick  loading  of  videos  from  all  locations.
        </TextModal>
        </>
    )
};