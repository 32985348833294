import React from 'react';

import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { HeaderContainer,Description,Logo,Title } from '../../global.css';


const Header = ({ title, description }) =>{ 
  
  const headerStyle = {
    display:"flex",
    flexDirection:"row",
    justifyContent:"left",
    flex:"0 1 auto",        
    paddingTop:"0.75vh",
    paddingBottom:"0.25vh",
    paddingLeft:"1vw",
    paddingRight:"1vw",        
    alignItems:"center",
    width:"100vw",        
    maxHeight:"5vh",
    height:"5vh", 

};
  const logoStyle = {
    display: "flex",
    flexDirection:"row",
    justifyContent:"flex-start",
    alignItems:"flex-start",
    paddingLeft:"0.1vw",
    paddingRight:"0.1vw",
    paddingTop:"0.1vh",
    paddingBottom:"0.1vh",
    margin:"0",
    width:"min(4vh,4vw)",
    maxHeight:"4vh",
    maxWidth:"5%",
};
  const siteTitleStyle = {
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-start",        
    alignItems:"center",
    paddingLeft:"0.1vw",
    paddingRight:"0.1vw",
    margin:"0",
    height:"4vh",
    maxHeight:"4vh",
    width:"35%",
    maxWidth:"35%",
    fontSize:"min(4vh,4vw)",
    fontWeight: "700",
    color:"rgb(0,100,0)",        
};

  const siteDescriptionStyle = {
    display:"flex",
    flexDirection:"row",
    flexWrap:"wrap",
    justifyContent:"flex-end",
    textAlign:"right",
    alignItems:"center",
    padding:"0",
    margin:"0",
    height:"4vh",
    maxHeight:"4vh",
    width:"60%",
    maxWidth:"60%",
    fontSize:"min(3vh,3vw)",
    fontWeight:"700",         
};

  const dataLogo = useStaticQuery(graphql`
   query dataLogoQuery {
    file(relativePath: {eq: "logo.png"}) {
      childImageSharp {
        gatsbyImageData(pngOptions: {quality: 100}, quality: 100)
      }
    }
  }
  
  `);

const image = getImage(dataLogo.file.childImageSharp.gatsbyImageData);

  
  
  return(
    <HeaderContainer style = {headerStyle}>
      
        <Logo style = {logoStyle}>
        <GatsbyImage image={image} style={{height:"100%"}} alt="Logo-Icon"/>
        </Logo>
        <Title style={siteTitleStyle}>
          <Link to="/">
          {title}         
          </Link>
        </Title>
        <Description style={siteDescriptionStyle}>{description}</Description>
                
      
      
    </HeaderContainer>
  
)};


export default Header;
