import React,{createContext, useState} from "react";
import { StyleAll } from "../styleall";

const BreakpointContext = createContext({});
const BreakpointProvider= ({children}) => {
  const isCSR = typeof window !== "undefined";
  const width = isCSR ? window.innerWidth : 1280;
  const height = isCSR ? window.innerHeight : 720;  
  
  const factor = (width / height).toFixed(2);
  const initialValue = StyleAll(factor);
  const [windowSize, setWindowSize] = useState(initialValue);

 

  function changeWindowSize() {
    const isCSR = typeof window !== "undefined";
    const width = isCSR ? window.innerWidth : 1280;
    const height = isCSR ? window.innerHeight : 720;   
    
    const factor = (width / height).toFixed(2);
                
    const currentValue =StyleAll(factor);
    setWindowSize(currentValue);
    
  }
        
        
   
  

  React.useEffect(() => {
    window.addEventListener("resize", changeWindowSize);

    return () => {
      window.removeEventListener("resize", changeWindowSize);
    };
  }, []);

  return (
    <BreakpointContext.Provider value = {windowSize}>
        {children}
    </BreakpointContext.Provider>
)
};
export {BreakpointContext,BreakpointProvider};