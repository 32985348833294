import React from 'react';
import favicon from "../../images/favicon.ico";

import { StaticQuery, graphql } from 'gatsby';
import { Location } from '@gatsbyjs/reach-router';
import SchemaGenerator from '../../helpers/schemaGenerator';


export const Head = ({
  siteTitle,
  siteDescription,
  siteUrl,  
  location,
  canonical = siteUrl + (location.pathname || ''),
}) => (
  <>
    <html lang="en" />
    <meta charSet="utf-8" />
    <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
    <meta
      content="width=device-width,initial-scale=1.0,user-scalable=yes"
      name="viewport"
    />

    <meta content={siteTitle} name="apple-mobile-web-app-title" />
    <meta content={siteTitle} property="og:title" />
    <meta content={siteTitle} name="twitter:title" />
    <title>{siteTitle}</title>

    <meta content={siteDescription} name="description" />
    <meta content={siteDescription} property="og:description" />
    <meta content={siteDescription} name="twitter:description" />

    <meta content="yes" name="apple-mobile-web-app-capable" />
    <meta
      content="black-translucent"
      name="apple-mobile-web-app-status-bar-style"
    />
    
    <meta content={siteDescription} name="application-name" />

    <meta content="website" property="og:type" />
    <meta content={siteTitle} property="og:site_name" />
    
    <link rel="canonical" href={canonical} />

    <link rel="icon" href={favicon} />

    <link
      href="/icons/favicon-32.png"
      rel="icon"
      sizes="32x32"
      type="image/png"
    />
    <link
      href="/icons/favicon-24.png"
      rel="icon"
      sizes="24x24"
      type="image/png"
    />
    <link
      href="/icons/favicon-16.png"
      rel="icon"
      sizes="16x16"
      type="image/png"
    />

    <script type="application/ld+json">
      {JSON.stringify(
        SchemaGenerator({          
          canonical,
          siteUrl,         
          siteTitle, 
          siteDescription,         
        })
      )}
    </script>
  </>
);


const HeadWithQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteTitle            
            siteDescription
            siteUrl      
          }  
        }
      }
    `}
    render={data => (
      <Location>
        {({ location }) => (
          <Head {...data.site.siteMetadata} {...props} location={location} />
        )}
      </Location>
    )}
  />
);

export default HeadWithQuery;
