 
import React,{useContext} from 'react';
import { BreakpointContext } from '../../providers/breakpoint';
import NormalInstant from "../../constants/Images/normalInstant";
import NormalStorage from "../../constants/Images/normalStorage";
import NormalOptions from "../../constants/Images/normalOptions";
import NormalMonitor from "../../constants/Images/normalMonitor";
import NormalAlert from "../../constants/Images/normalAlert";
import { ImageContainer } from '../../global.css';
import NormalMain from '../../constants/Images/normalMain';


export default function NormalTitleClick(props) {
   
  const styleAll = useContext(BreakpointContext);
    const imageContainerStyle = styleAll['ImageContainerStyle'];
    return (
        <ImageContainer
        key={"ImageContainer"} 
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 100 }}
        transition={{
            type: "spring",
            mass: 0.5,
            stiffness: 50,
            duration: 0.2,
            staggerChildren:0.2,
            delayChildren:0.2,
        }}             
        style={imageContainerStyle}
      >
      {
        ((props.title === "title-1") && <NormalMain key="normalmain" largeImage ={props.largeImage} OnDoubleClick ={props.OnDoubleClick}/>) ||        
        ((props.title === "title-2") && <NormalMonitor key="normalmonitor" largeImage ={props.largeImage} OnDoubleClick ={props.OnDoubleClick}/>) ||
        ((props.title === "title-3") && <NormalStorage key="normalstorage" largeImage ={props.largeImage} OnDoubleClick ={props.OnDoubleClick}/>) ||
        ((props.title === "title-4") && <NormalAlert key="normalalert" largeImage ={props.largeImage} OnDoubleClick ={props.OnDoubleClick}/>) ||
        ((props.title === "title-5") && <NormalOptions key="normaloptions" largeImage ={props.largeImage} OnDoubleClick ={props.OnDoubleClick}/>) ||                           
        ((props.title === "title-6") && <NormalInstant key="normalinstant" largeImage ={props.largeImage} OnDoubleClick ={props.OnDoubleClick}/>)
      }
      </ImageContainer>
    )
};


