import React,{useContext} from  'react';
import { BreakpointContext } from '../../providers/breakpoint';
import ModalInstant from "../../constants/Images/modalInstant";
import ModalStorage from "../../constants/Images/modalStorage";
import ModalAlert from '../../constants/Images/modalAlert';
import ModalOptions from "../../constants/Images/modalOptions";
import ModalMonitor from "../../constants/Images/modalMonitor";
import {SideContainer, ModalContainer, CloseContainer, ClientArrowContainer, ArrowContainer, ClientContainer } from '../../global.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes,faAngleLeft,faAngleRight} from "@fortawesome/free-solid-svg-icons";
import FeatureMenu from '../../constants/content/featureMenu';
import ModalMain from '../../constants/Images/modalMain';
import { FeatureAlertNormal, FeatureInstantNormal, FeatureMainNormal, FeatureMonitorNormal, FeatureOptionsNormal, FeatureStorageNormal } from '../../constants/content/features';
import { NormalFeature } from '../../global.css';
export default function ModalTitleClick(props){

  const styleAll = useContext(BreakpointContext);
  const sideContainerStyle = styleAll['SideContainerStyle'];
  const modalContainerStyle= styleAll['ModalContainerStyle'];
  const closeContainerStyle = styleAll['CloseContainerStyle'];    
  const closeIconStyle =styleAll['CloseIconStyle']; 
  const clientContainerStyle = styleAll['ClientContainerStyle'];
  const clientArrowContainerStyle = styleAll['ClientArrowContainerStyle'];
  const arrowContainerStyle = styleAll['ArrowContainerStyle'];
  const normalFeatureStyle = styleAll['NormalFeatureStyle'];
  const factor = styleAll['factor'];

  const sliderClose = (event) =>{
    props.imageLoader();
    event.preventDefault();
  };
  const sliderLeft = (event) =>{
    props.imageSliderLeft();
    event.preventDefault();
  };
  const sliderRight = (event) =>{
    props.imageSliderRight();
    event.preventDefault();
  };

  const ftr = {
    hidden: { opacity: 0},
    show: {
      opacity: 1 ,
    transition:{ 
      type: "spring",
      mass: 0.25,
      stiffness: 75,
      duration:1,
      staggerChildren:0.5,                   
      delayChildren:0.5,
        
      }
    },
   
   
  };


    return (
      <SideContainer 
            key="sidecontainer"  
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{
                type: "spring",
                mass: 0.5,
                stiffness: 50,
                duration: 0.2,
                staggerChildren:0.2,
                delayChildren:0.2,
            }}                
            style={sideContainerStyle}
      >
     
      
      <NormalFeature 
          key="normalfeature" 
          variants={ftr}
          initial="hidden"
          animate="show"                                               
          style={normalFeatureStyle}
      >
      {       
        (factor <= 1) ?
        <FeatureMainNormal key="featuremainnormal"/>
        : 
               (
                  ((props.title === "title-1") && <FeatureMainNormal key="featuremainnormal"/>) ||        
                  ((props.title === "title-2") && <FeatureMonitorNormal key="featuremonitornormal"/>) ||
                  ((props.title === "title-3") && <FeatureStorageNormal key="featurestoragenormal"/>) ||
                  ((props.title === "title-4") && <FeatureAlertNormal key="featurealertnormal"/>) ||
                  ((props.title === "title-5") && <FeatureOptionsNormal key="featureoptionsnormal"/>) ||
                  ((props.title === "title-6") && <FeatureInstantNormal key="featureinstantnormal" />)
               ) 
     }
      </NormalFeature>
    
      <FeatureMenu 
        onClicked ={props.OnMenuClicked}                
      />   
      {
        props.modalSlider 
        ?
        <ModalContainer
        key={"modalslider"}
        style={modalContainerStyle}
        onDoubleClick={sliderClose}                                          
        >
          <CloseContainer style={closeContainerStyle}>
            <FontAwesomeIcon icon={faTimes} style={closeIconStyle} onClick = {sliderClose} />
          </CloseContainer>
          <ClientArrowContainer style={clientArrowContainerStyle}>
              <ArrowContainer onClick={sliderLeft} style={arrowContainerStyle}>
                <FontAwesomeIcon icon={faAngleLeft} style={closeIconStyle} />
              </ArrowContainer>
              <ClientContainer
              key="clientcontainer"
              variants={ftr}
              initial="hidden"
              animate="show"
              style={clientContainerStyle}
              >
              {
                ((props.title === "title-1") && <ModalMain key="modalmain"/>) ||        
                ((props.title === "title-2") && <ModalMonitor key="modalmonitor"/>) ||
                ((props.title === "title-3") && <ModalAlert key="modalalert"/>) ||
                ((props.title === "title-4") && <ModalStorage key="modalstorage"/>) ||
                ((props.title === "title-5") && <ModalOptions key="modaloptions"/>) ||
                ((props.title === "title-6") && <ModalInstant key="modalinstant"/>) 
              }
              </ClientContainer>
              <ArrowContainer onClick={sliderRight} style={arrowContainerStyle}>
                <FontAwesomeIcon icon={faAngleRight} style={closeIconStyle} />
              </ArrowContainer>   
          </ClientArrowContainer>             
        </ModalContainer>
        : null
      }      
      </SideContainer>
    )
};


