import React,{useContext} from 'react';
import {useStaticQuery,graphql} from 'gatsby';
import { BreakpointContext } from '../../providers/breakpoint';
import { ModalFeature, ModalPicture } from "../../global.css";
import {GatsbyImage,getImage} from 'gatsby-plugin-image';
import { FeatureMainModal } from '../content/features';




const ModalMain = (props) =>{

  const styleAll = useContext(BreakpointContext);
  const modalPictureStyle = styleAll['ModalPictureStyle'];
  const modalFeatureStyle  = styleAll['ModalFeatureStyle'];
  const imageStyle = styleAll['ModalImageStyle'];

  const dataImageMainModal = useStaticQuery(graphql`
  query dataImageMainModalQuery {
  file(relativePath: {eq: "img_main.png"}) {
      childImageSharp {
      gatsbyImageData(pngOptions: {quality: 80}, placeholder: BLURRED)
      }
  }
  }
  `);

  const image = getImage(dataImageMainModal.file.childImageSharp.gatsbyImageData);
  
  const ftr = {
    hidden: { opacity: 0},
    show: {
      opacity: 1 ,
    transition:{ 
      type: "spring",
      mass: 0.25,
      stiffness: 75,
      duration:1,
      staggerChildren:0.5,                   
      delayChildren:0.5,
        
      }
    },
   
  };
    
  
    return (
        <>    
        <ModalPicture
            key="modalpicturemain"             
            style={modalPictureStyle}
          >

          <GatsbyImage image={image} style={imageStyle} alt="Multilocation-Video-Monitoring-App"/>

        </ModalPicture>
        <ModalFeature
          key="modalfeaturemain"
          variants={ftr}
          initial="hidden"
          animate="show"
          style={modalFeatureStyle}
          >
            <FeatureMainModal key="featuremainmodal"/>
        </ModalFeature>
        
        </>
    )
};

export default ModalMain;

