import React,{useContext} from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { BreakpointContext } from "../../providers/breakpoint";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes} from "@fortawesome/free-solid-svg-icons";
import { NormalPicture, CloseContainer, ClientContainer, ModalContainer } from "../../global.css";

const NormalAlert = (props) => {
  const styleAll = useContext(BreakpointContext);
  const modalContainerStyle = styleAll['ModalContainerStyle'];
  const closeContainerStyle  = styleAll['CloseContainerStyle'];
  const closeIconStyle = styleAll['CloseIconStyle']; 
  const clientContainerStyle  = styleAll['ClientContainerStyle'];
  const normalPictureStyle = styleAll['NormalPictureStyle'];  

  const imageStyle = styleAll['ModalImageStyle'];
  
  const dataNormalAlert = useStaticQuery(graphql`
  query dataNormalAlertQuery {
    file(relativePath: {eq: "img_alert.png"}) {
        childImageSharp {
        gatsbyImageData(pngOptions: {quality: 80}, placeholder: BLURRED)
      }
    }
  }
  `);

  const image = getImage(dataNormalAlert.file.childImageSharp.gatsbyImageData);
  
  

      const OnDoubleClk = (event) => {
      props.OnDoubleClick();
      event.preventDefault();
      }
  
  
  return (
    <>
               
          <NormalPicture 
            key="normalpicturealert"
            initial={{ opacity: 0, x: 0, y: -20 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{ opacity: 0, x: 20, y: 0 }}
            transition={{
            type: "spring",
            mass: 0.5,
            stiffness: 50,
            duration:0.5,
            }}
          style={normalPictureStyle}
          >
            <GatsbyImage image={image} onDoubleClick={OnDoubleClk} style={{height:"100%",}} alt="CCTV-Alert-Video-Popup-Image"/>
          </NormalPicture>

          {
            props.largeImage ? 
          
          
          <ModalContainer
            key="modalimagenormal"
            initial={{ opacity: 0, x: -200 , y: -200}}
            animate={{ opacity: 1, x: 0 , y: 0}}
            exit={{ opacity: 0, x: 200, y: 200 }}
            transition={{
            type: "spring",
            mass: .25,
            stiffness: 50,
            duration:0.5,
            }}
            style={modalContainerStyle}
            onDoubleClick={OnDoubleClk}
          >
            <CloseContainer style={closeContainerStyle}>
              <FontAwesomeIcon icon={faTimes} style={closeIconStyle} onClick = {OnDoubleClk} />
            </CloseContainer>
            <ClientContainer style={clientContainerStyle}>            
            <GatsbyImage image={image} style={imageStyle} alt="CCTV-Alert-Video-Popup-Image" />
            </ClientContainer>
          </ModalContainer>
          
          : null
          }
    </>
  );
};

export default NormalAlert;