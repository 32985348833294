import React,{useContext} from "react";
import { TitleContainer } from "../../global.css";
import { FeatureTitleMonitor,FeatureTitleStorage,FeatureTitleOptions,FeatureTitleInstant, FeatureTitleMain, FeatureTitleAlert } from "./featureTitle";
import { BreakpointContext } from "../../providers/breakpoint";
export default function FeatureMenu(props) {
    const styleAll = useContext(BreakpointContext);
    const titleContainerStyle = styleAll['ButtonContainerStyle'];
    
    return (
        <TitleContainer 
              key="titlecontainer" 
              style={titleContainerStyle}      
              >
              <FeatureTitleMain {...props} />  
              <FeatureTitleMonitor {...props} />               
              <FeatureTitleStorage {...props} />  
              <FeatureTitleAlert {...props} />        
              <FeatureTitleOptions {...props} />           
              <FeatureTitleInstant {...props} />
              
        </TitleContainer>
    )
}
