// This does not support nested pages (level 2 and up)
// If you're working with deeply nested pages, remove this or rework it.

export const SchemaGenerator = ({  
  canonical,
  siteUrl,  
  siteTitle,
  siteDescription,
}) => {
  

  let schema = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: canonical,
      name: siteDescription || siteTitle,
      
    },
  ];


  return schema;
};

export default SchemaGenerator;
